import styled from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";
import {
  StyledFormBlock,
  StyledFormSubmit,
} from "../../commons/Form";

const maxWidth = 1248;

export const StyledNewsletterForm = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: ${Rem(70)} auto ${Rem(70)};
  padding: ${Rem(20)};
  background-color: ${Colors.greenlight};
  @media (${BP.ipad}) {
    border-radius: 20px;
    margin: ${Rem(140)} auto ${Rem(150)};
    max-width: ${Rem(maxWidth)};
    padding: ${Rem(30)};
  }

  ${StyledFormBlock} {
    @media (${BP.ipad}) {
      width: calc(100% - ${Rem(260)});
    }
  }
`;

export const StyledNewsletterFormBlock = styled.div`
  width: 100%;

  @media (${BP.ipad}) {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    > * {
      position: relative;
      width: 50%;

      &:first-child {
        padding-right: ${Rem(170)};
        padding-top: ${Rem(24)};
      }
    }
  }
`;

export const StyledNewsletterFormName = styled.h2`
  font-size: ${Rem(14)};
  padding-bottom: ${Rem(24)};

  @media (${BP.ipad}) {
    font-size: ${Rem(15)};
    letter-spacing: ${Rem(5)};
  }
`;

export const StyledNewsletterFormTitle = styled.h4`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
  padding-bottom: ${Rem(24)};
`;

export const StyledNewsletterFormParagraph = styled.p`
  display: none;
  font-size: ${Rem(12)};
  line-height: ${Rem(14)};

  @media (${BP.ipad}) {
    display: inline-block;
  }
`;

export const StyledNewsletterFormBigText = styled.h3`
  font-size: ${Rem(30)};
  line-height: ${Rem(37)};
  padding-bottom: ${Rem(24)};

  @media (${BP.ipad}) {
    font-size: ${Rem(50)};
    line-height: ${Rem(55)};
  }
`;

export const StyledNewsletterFormSubmit = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${Rem(24)};

  @media (${BP.ipad}) {
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 0;
    height: 100%;
  }

  ${StyledFormSubmit} {
    @media (${BP.ipad}) {
      height: calc(100% - ${Rem(16)});
    }
  }
`;
