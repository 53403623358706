import React, { useContext } from "react";
import BusinessIconText from "../components/BusinessIconText/BusinessIconText";
import Faq from "../components/Faq/Faq";
import Spacer from "../components/Spacer/Spacer";
import Intro from "../components/Intro_v2/Intro_v2";
import Meta from "../components/Meta/Meta";
import BusinessSlider from "../components/BusinessSlider/BusinessSlider";
import NewsletterForm from "../components/NewsletterForm/NewsletterForm";
import SectionTwoCol from "../components/SectionTwoCol/SectionTwoCol";
import Hero3BC from "../components/Hero3BC/Hero3BC";
import { DataContext } from "../contexts/DataContext";
import useSnipcartEvents from "../hooks/useSnipcartEvents";
import BusinessImpactNew from "../components/BusinessImpactNew/BusinessImpactNew";
import { toast } from "react-toastify";

const AdottaUnApe = () => {
  const {
    labels,
    pageLabels,
    languages,
    pageImages,
  } = useContext(DataContext);
  const currentLanguage = languages.filter((language) => language.isCurrent);
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : "en";
  useSnipcartEvents();
  toast.configure();

  return (
    <>
      <Meta />
      <Hero3BC 
        title = {pageLabels.hero.title}
        image = "/2020/images/adotta-un-ape/culino_osmia.jpg"
        paragraph = {pageLabels.hero.paragraph}
        buttonText = {pageLabels.hero.cta}
        to = "#products"
        newsletterButtonText = {labels.SUBSCRIBE}
        newsletterTitle = {labels.NEWSLETTER}
        titleBackground = "transparent"
        lang={currentLanguageCode}
        success = "Riceverai una mail con i nostri progetti e aggiornamenti non noiosi sul mondo delle api e della sostenibilità"
        error="Error. Contact us on info@3bee.com"
        leftButtonProps={{
          text: labels.CSR,
          to: "/business",
        }}
        newsletterListType = "businessPage"
        allImages = {pageImages}
        boxTitle={labels.protect}
      /> 

      <Spacer size={10} />
      <Intro
        bigText={pageLabels.story.title}
        textAlign="center"
        id="products"
      />

      <BusinessIconText
        items={[{
          image: "/2020/images/adotta-un-ape/osmiaHouse.png",
          label: pageLabels.story.hr.label,
          text: pageLabels.story.hr.text,
        }, {
          image: "/2020/images/business/HR_icon.png",
          label: pageLabels.story.comunication.label,
          text: pageLabels.story.comunication.text,
        }, {
          image: "/2020/images/adotta-un-ape/balcony.png",
          label: pageLabels.story.csr.label,
          text: pageLabels.story.csr.text,
        }]}
      />

      <div style={{ height: "3.5rem" }}></div>
      <div style="text-align: center;">Spedizione non Garantita per Natale</div>
      <div style={{ height: "5rem" }}></div>
      
      <BusinessSlider
        items={pageLabels.products}
        allImages={pageImages}
      />
      <div style={{ height: "8.5rem" }}></div>
      <SectionTwoCol
        title={pageLabels.section1.title}
        subtitle1={pageLabels.section1.subtitle1}
        to="#products"
        image={pageLabels.section1.image}
        cta={pageLabels.section1.cta}
      />

      <div style={{ height: "5.5rem" }}></div>

      <SectionTwoCol
        title={pageLabels.section2.title}
        subtitle1={pageLabels.section2.subtitle1}
        to="#products"
        image={pageLabels.section2.image}
        rightImage={true}
        cta={pageLabels.section2.cta}
      />
      <div style={{ height: "2.5rem" }}></div>
      <NewsletterForm />
      <div style={{ height: "2.5rem" }}></div>

      <SectionTwoCol
        title={pageLabels.section3.title}
        subtitle1={pageLabels.section3.subtitle1}
        to="#products"
        image={pageLabels.section3.image}
        cta={pageLabels.section3.cta}
      />

      <div style={{ height: "5.5rem" }}></div>

      <SectionTwoCol
        title={pageLabels.section4.title}
        subtitle1={pageLabels.section4.subtitle1}
        to="#products"
        image={pageLabels.section4.image}
        cta={pageLabels.section4.cta}
        rightImage={true}
      />

      <div style={{ height: "5.5rem" }}></div>
      <BusinessImpactNew
        image={pageLabels.businessImpact.image}
        title={pageLabels.businessImpact.title}
        subtitle={pageLabels.businessImpact.subtitle}
        button={pageLabels.businessImpact.cta}
        link="/adotta-alveare/"
      />

      <Faq
        data={pageLabels.faq.dataFile.data}
      />
    </>
  );
};

export default AdottaUnApe;
