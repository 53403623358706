import React, { useContext } from "react";
import { StyledGridRow } from "../../commons/Grid";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  StyledBusinessImpact,
  StyledBusinessImpactTop,
  StyledBusinessImpactBottom,
  StyledBusinessImpactTitle,
  StyledBusinessImpactItems,
  StyledBusinessImpactItemsGroup,
  StyledBusinessImpactItem,
  StyledBusinessImpactItemBig,
  StyledBusinessImpactItemSmaller,
} from "./style";
import { Button } from "@3beehivetech/components";
import { DataContext } from "../../contexts/DataContext";
import useNavigate from "../../hooks/useNavigate";

const BusinessImpactNew = ({
  title, subtitle, item1, item2, item3, item4, image, button, link,
}) => {
  const { pageImages } = useContext(DataContext);  
  const imageData = pageImages.find(e => e.path === image);

  return (
    <StyledGridRow id="businessimpact">
      <StyledBusinessImpact>
        <GatsbyImage 
          style={{ width: "100%", minHeight: "400px", maxHeight: "520px" }} 
          imgStyle={{ borderRadius: "10px", filter: "brightness(0.8)" }} 
          image={getImage(imageData)} 
          alt={imageData.alt} />
        <div style="position: absolute; width: 100%; height: 100%; top: 0px;">
          <StyledBusinessImpactTop>
            <StyledBusinessImpactTitle dangerouslySetInnerHTML={{ __html: title }}/>
          </StyledBusinessImpactTop>

          <StyledBusinessImpactBottom>
            <StyledBusinessImpactItems>
              <StyledBusinessImpactItemsGroup>
                { item1 &&<BusinessImpactItem  item={item1}/>}
                { item2 &&<BusinessImpactItem  item={item2}/>}
              </StyledBusinessImpactItemsGroup>
              <StyledBusinessImpactItemsGroup>
                {item3 &&<BusinessImpactItem  item={item3}/>}
                {item4 &&<BusinessImpactItem  item={item4}/>}
              </StyledBusinessImpactItemsGroup>
            </StyledBusinessImpactItems>
          </StyledBusinessImpactBottom>
        </div>
        <Button 
          style={{
            display: button && link ? "block": "none", position: "absolute", bottom: "30px", right: "30px", 
          }}
          onClick={useNavigate(link)} >
          {button}{" "}
        </Button>
      </StyledBusinessImpact>
    </StyledGridRow>
  );
};

const BusinessImpactItem = ({ item }) => {
  return (
    <StyledBusinessImpactItem>
      <StyledBusinessImpactItemBig dangerouslySetInnerHTML={{ __html: item.big }}/>
      <span dangerouslySetInnerHTML={{ __html: item.small }}/>
      {item.smaller && (
        <StyledBusinessImpactItemSmaller dangerouslySetInnerHTML={{ __html: item.smaller }}/>
      )}
    </StyledBusinessImpactItem>
  );
};  

export default BusinessImpactNew;
